import React from 'react';
import { makeId } from '../utils';
import technologies from './technologies';

// import slxCover from '../images/logos/slx.webp';
import reactSVGCover from '../images/logos/react.svg';
import cpsCover from '../images/logos/cps.webp';
import ptCover from '../images/logos/pt.webp';

import guiAvatar from '../images/testimonials/gui.webp';

const {
  typescript,
  react,
  redux,
  nodejs,
  mongodb,
  // postgresql,
  materialUI,
  socketIO,
  // bootstrap,
  sass,
  rollup,
  babel,
  jest,
  eslint,
  storybook,
  heroku,
  // aws,
  shopify,
  stripe,
  twilio,
  // arcgis,
} = technologies;

export default [
  // {
  //   title: 'Streetlogix',
  //   get id() {
  //     return makeId(this.title);
  //   },
  //   technologies: [react, redux, nodejs, typescript, postgresql, arcgis, aws],
  //   liveLink: 'https://streetlogix.com/',
  //   image: slxCover,
  //   content: (
  //     <div>
  //       <p></p>
  //     </div>
  //   ),
  //   testimonial: {
  //     name: 'John Doe',
  //     designation: 'Director of Product',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.',
  //     avatar: 'https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).webp',
  //     url: 'https://linkedin.com/',
  //   },
  // },
  {
    title: 'Create React Package',
    subtitle: 'Open-Source Project',
    get id() {
      return makeId(this.title);
    },
    technologies: [
      nodejs,
      typescript,
      react,
      rollup,
      babel,
      eslint,
      jest,
      storybook,
    ],
    liveLink: 'https://github.com/haseebanwar/create-react-pkg',
    imageType: 'svg',
    image: reactSVGCover,
    content: (
      <div>
        <p>
          Create React Package is a command-line build tool for creating,
          building, testing, and publishing React Libraries.
        </p>
        <p>
          Many developers struggle with setting the boilerplate for React
          Libraries as it requires installing and configuring a compiler,
          bundler, test runner, and various build tools. Create React Package
          integrates these build tools and provides a seamless, optimized,
          easy-to-use build pipeline for creating React Libraries.
        </p>
        <p>With Create React Package, developers get:</p>
        <ul>
          <li>CJS, ESM, and UMD builds of the library</li>
          <li>Rollup, Babel, ESLint, and Jest pre-configured</li>
          <li>Ability to customize all the build tools</li>
          <li>Integrated Playground</li>
          <li>TypeScript and Storybook support</li>
          <li>Out-of-the-box support for CSS, SASS, and JSON files</li>
          <li>
            Treeshaking, Code-splitting, and separate dev/production builds
          </li>
          <li>
            Pre-configured Browserslist to control the output JavaScript and CSS
            assets
          </li>
          <li>Better DX and maintainability with just one dependency</li>
        </ul>
      </div>
    ),
    testimonial: null,
  },
  {
    title: 'Central Pro Services',
    get id() {
      return makeId(this.title);
    },
    technologies: [react, redux, nodejs, mongodb, sass, materialUI, heroku],
    liveLink: 'https://www.centralproserv.com/',
    image: cpsCover,
    content: (
      <div>
        <p>
          Central Pro Services is a renowned renovation service provider in the
          United States. I was privileged to contribute to enhancing and
          streamlining the organization's operations through developing and
          maintaining software.
        </p>
        <ul>
          <li>
            Optimized the order and sales systems by implementing advanced
            features for managing Teams, Technicians, and Pricing of the
            Products and Services. Leading to increased customer satisfaction
            and repeat business.
          </li>
          <li>
            Improved software architecture; implemented modern coding and
            testing principles and CI/CD for faster and more reliable releases.
          </li>
        </ul>
      </div>
    ),
    testimonial: {
      name: 'Guillermo Vazquez',
      designation: 'Director of Business Development at Central Pro Services',
      text: 'Haseeb is a highly talented software developer with exceptional technical and communication skills. He worked for us on a few different projects, and during that time, he demonstrated excellent problem-solving skills, attention to detail, and a strong work ethic.',
      avatar: guiAvatar,
      url: null,
    },
  },
  {
    title: 'Pawket Treats',
    get id() {
      return makeId(this.title);
    },
    technologies: [
      react,
      redux,
      nodejs,
      socketIO,
      mongodb,
      shopify,
      stripe,
      twilio,
    ],
    liveLink: 'https://pawkettreats.com/',
    image: ptCover,
    content: (
      <div>
        <p>
          Pawket Treats is a platform for selling dog treats. Built on Shopify,
          powered by an exclusive custom app.
        </p>
        <p>
          Co-architected and led the development of the custom app, empowering
          its customers with:
        </p>
        <ul>
          <li>One-step checkout.</li>
          <li>Customer authentication and order confirmation via SMS.</li>
          <li>
            Custom-built subscription model with automatic subscription
            confirmation and payment charging.
          </li>
          <li>
            One-time and recurring order payments (with 3D Secure). Powered by
            Stripe.
          </li>
          <li>
            Customer support via text with a real-time messaging interface for
            admins.
          </li>
        </ul>
      </div>
    ),
    testimonial: null,
  },
];
