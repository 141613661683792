import moment from 'moment';

export default [
  {
    title: '8 Best Practices Every React Developer Should Follow',
    date: moment('2021-10-12'),
    link: 'https://betterprogramming.pub/8-best-practices-every-react-developer-should-follow-44177670c646',
    views: '20',
  },
  {
    title: '10 Modern JavaScript Tricks Every Developer Should Use',
    date: moment('2021-06-15'),
    link: 'https://betterprogramming.pub/10-modern-javascript-tricks-every-developer-should-use-377857311d79',
    views: '66',
  },
  {
    title: 'The Complete Guide to OAuth 2.0 and OpenID Connect Protocols',
    date: moment('2020-06-02'),
    link: 'https://medium.com/better-programming/the-complete-guide-to-oauth-2-0-and-openid-connect-protocols-35ebc1cbc11a',
    views: '18',
  },
  {
    title: 'Architecting a GraphQL API Codebase in Node.js',
    date: moment('2021-09-28'),
    link: 'https://betterprogramming.pub/architecting-a-graphql-api-codebase-in-node-js-3a951cd7f0f4',
    views: '7.2',
  },
  {
    title: '7 Custom React Hooks You Probably Need in Your Project',
    date: moment('2021-08-19'),
    link: 'https://betterprogramming.pub/7-custom-react-hooks-you-probably-need-in-your-project-12f86216df94',
    views: '5.9',
  },
  {
    title: '4 JavaScript Promise Methods — All, AllSettled, Any, and Race',
    date: moment('2022-02-08'),
    link: 'https://betterprogramming.pub/4-javascript-promise-methods-all-allsettled-any-and-race-ba0e0c8c034e',
    views: '6.2',
  },
  {
    title: 'Understand Keys, Virtual DOM, Reconciliation, and Diffing in React',
    date: moment('2021-10-26'),
    link: 'https://betterprogramming.pub/understand-keys-virtual-dom-reconciliation-and-diffing-in-react-e65a9bee316f',
    views: '3.8',
  },
  {
    title: 'MongoDB for VS Code: A Better Way to Work With Mongo',
    date: moment('2020-05-19'),
    link: 'https://medium.com/better-programming/mongodb-for-vs-code-a-better-way-to-work-with-mongo-e4de95c921c2',
    views: '4.6',
  },
  {
    title: 'How to Load Images Progressively in React',
    date: moment('2020-04-23'),
    link: 'https://medium.com/better-programming/how-to-load-images-progressively-in-react-a320e5572c15',
    views: '7',
  },
  {
    title: 'Nodejs: Optimize With Gulp and Brotli',
    date: moment('2020-09-14'),
    link: 'https://medium.com/swlh/nodejs-optimize-with-gulp-and-brotli-52cfb70fdea',
    views: '2.6',
  },
];
