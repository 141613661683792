import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { withRouter } from 'react-router-dom';
import { MainContent } from '../layouts';
import plug from '../images/icons/plug.svg';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.goToHome = this.goToHome.bind(this);
  }

  componentDidMount() {
    document.title = 'Page Not Found - 404';
  }

  goToHome() {
    this.props.history.push('/');
  }

  render() {
    return (
      <MainContent>
        <div className="NotFound">
          <h1 className="title display-3">
            4
            <ReactSVG src={plug} className="plug" />4
          </h1>
          <p className="text">Page not found</p>
          <button className="btn btn-primary" onClick={this.goToHome}>
            Go to Homepage
          </button>
        </div>
      </MainContent>
    );
  }
}

export default withRouter(NotFound);
