import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

class PostCard extends Component {
  render() {
    const { title, link, views } = this.props.post;

    return (
      <div className="PostCard card">
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className="card-body d-flex justify-content-between">
            <div>
              <h6 className="card-title">{title}</h6>
              <p className="card-subtitle text-muted">{views}k views</p>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                className="text-muted"
                size="sm"
              />
            </div>
          </div>
        </a>
      </div>
    );
  }
}

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostCard;
