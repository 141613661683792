import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MainContent, Underline } from '../layouts';
import { injectPageMetaData } from '../utils';
import metadata from '../data/metadata';

class Home extends Component {
  componentDidMount() {
    injectPageMetaData(metadata.home);
  }

  render() {
    return (
      <MainContent style={{ background: 'transparent' }}>
        <div className="Home">
          <div className="sr-only">
            I'm a Full-Stack Engineer, Technical Writer, and Content Creator.
            Specializing in JavaScript with technologies like React, NodeJS,
            SQL/NoSQL databases, and various development tools.
          </div>
          <div className="headings">
            <h1 className="pre-title">
              <span role="img" aria-label="" className="pr-3">
                👋
              </span>
              hey,
            </h1>
            <h1 className="title display-4">I'm Haseeb</h1>
            <h1 className="post-title">
              Full-Stack Engineer and Technical Writer
            </h1>
            <Underline width="70px" className="mt-4" />
          </div>
          <div className="mt-5">
            <Link to="/portfolio" className="btn btn-primary btn-lg">
              Portfolio
            </Link>
          </div>
        </div>
      </MainContent>
    );
  }
}

export default Home;
