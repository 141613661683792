import React, { Component } from 'react';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggelNavbar = this.toggelNavbar.bind(this);
    this.collapseNavbar = this.collapseNavbar.bind(this);
  }

  toggelNavbar() {
    this.setState((state) => ({
      open: !state.open,
    }));
  }

  collapseNavbar() {
    $('.navbar-collapse').collapse('hide');
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;

    return (
      <nav className="Navbar navbar navbar-expand-md navbar-dark">
        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/portfolio"
                onClick={this.collapseNavbar}
              >
                Portfolio
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="navigation-icon ml-2"
                />
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to="/skills"
                onClick={this.collapseNavbar}
              >
                Skills
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="navigation-icon ml-2"
                />
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to="/blog"
                onClick={this.collapseNavbar}
              >
                Blog
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="navigation-icon ml-2"
                />
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to="/contact"
                onClick={this.collapseNavbar}
              >
                Contact
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="navigation-icon ml-2"
                />
              </NavLink>
            </li> */}
          </ul>
        </div>
        <button
          className="navbar-toggler mt-3"
          type="button"
          data-toggle="collapse"
          data-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.toggelNavbar}
        >
          <FontAwesomeIcon icon={!open ? faChevronDown : faChevronUp} />
        </button>
      </nav>
    );
  }
}

export default Navbar;
