import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { Badge, Testimonial } from './';

class Project extends Component {
  constructor(props) {
    super(props);
    this.renderTechnologies = this.renderTechnologies.bind(this);
  }

  renderTechnologies() {
    const { technologies } = this.props.project;
    return (
      <div className="technologies">
        <h6 className="title mb-2">Technologies</h6>
        {technologies.map((technology) => (
          <Badge
            key={technology.name}
            name={technology.name}
            icon={technology.icon}
          />
        ))}
      </div>
    );
  }

  render() {
    // const { detailed } = this.props;
    const {
      title,
      subtitle,
      imageType,
      image,
      content,
      liveLink,
      testimonial,
    } = this.props.project;

    return (
      <div className="Project">
        <h3 className="title">
          {title}
          {/* {detailed ? title : <Link to={`/portfolio/${id}`}>{title}</Link>} */}
        </h3>
        {subtitle && <h5 className="subtitle">{subtitle}</h5>}
        <div className="row mt-3">
          <div className="col-md-7 content">
            <Fragment>
              {content}
              {/* {detailed ? content : introContent} */}
              {testimonial && (
                <Fragment>
                  <hr />
                  <Testimonial testimonial={testimonial} />
                </Fragment>
              )}
            </Fragment>

            <div className="btns mt-3">
              {/* {!detailed && (
                <Link to={`/portfolio/${id}`} className="btn btn-primary mr-2">
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                  Details
                </Link>
              )} */}
              {liveLink && (
                <a
                  href={liveLink}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faLink} className="mr-2" />
                  Website
                </a>
              )}
            </div>
          </div>
          <div className="col-md-5 image-badge-container">
            {this.renderTechnologies()}
            <div className="image-container">
              {imageType === 'svg' ? (
                <ReactSVG src={image} wrapper="span" />
              ) : (
                <img src={image} alt="" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Project.propTypes = {
  project: PropTypes.object.isRequired,
  detailed: PropTypes.bool,
};

export default Project;
