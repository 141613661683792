import React, { Component, Fragment } from 'react';
import { Context } from '../context';
import { MainContent, Project } from '../layouts';
import { injectPageMetaData } from '../utils';
import metadata from '../data/metadata';

class Portfolio extends Component {
  componentDidMount() {
    injectPageMetaData(metadata.portfolio);
  }

  render() {
    const { projects } = this.context;

    return (
      <MainContent>
        <div className="Portfolio">
          <h1>Portfolio</h1>
          <p>
            I've developed and maintained cutting-edge applications and
            solutions. Witness my passion for creating exceptional web
            experiences.
          </p>
          {projects.map((project, i) => (
            <Fragment key={project.id}>
              <Project project={project} />
              {i < projects.length - 1 && <hr />}
            </Fragment>
          ))}
        </div>
      </MainContent>
    );
  }
}

Portfolio.contextType = Context;

export default Portfolio;
