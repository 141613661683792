import {
  faJs,
  faNodeJs,
  faReact,
  faBootstrap,
  faHtml5,
  faCss3,
  faDocker,
  faGitAlt,
  faShopify,
  faStripeS,
  faAws,
} from '@fortawesome/free-brands-svg-icons';
import mongodbIcon from '../images/icons/mongodb.svg';
import reduxIcon from '../images/icons/redux.svg';
import herokuIcon from '../images/icons/heroku.svg';
import materialUIIcon from '../images/icons/materialui.svg';
import sassIcon from '../images/icons/sass.svg';
import webpackIcon from '../images/icons/webpack.svg';
import twilioIcon from '../images/icons/twilio.svg';
import socketIOIcon from '../images/icons/socket-io.svg';
import graphqlIcon from '../images/icons/graphql.svg';
import postgresqlIcon from '../images/icons/postgresql.svg';
import arcgisIcon from '../images/icons/arcgis.svg';
import typescriptIcon from '../images/icons/typescript.svg';
import rollupIcon from '../images/icons/rollup.svg';
import babelIcon from '../images/icons/babel.svg';
import storybookIcon from '../images/icons/storybook.svg';
import jestIcon from '../images/icons/jest.svg';
import eslintIcon from '../images/icons/eslint.svg';

export default {
  javascript: {
    name: 'JavaScript',
    icon: {
      type: 'font-awesome',
      icon: faJs,
    },
  },
  nodejs: {
    name: 'NodeJS',
    icon: {
      type: 'font-awesome',
      icon: faNodeJs,
    },
  },
  react: {
    name: 'React',
    icon: {
      type: 'font-awesome',
      icon: faReact,
    },
  },
  bootstrap: {
    name: 'Bootstrap',
    icon: {
      type: 'font-awesome',
      icon: faBootstrap,
    },
  },
  html: {
    name: 'HTML',
    icon: {
      type: 'font-awesome',
      icon: faHtml5,
    },
  },
  css: {
    name: 'CSS',
    icon: {
      type: 'font-awesome',
      icon: faCss3,
    },
  },
  docker: {
    name: 'Docker',
    icon: {
      type: 'font-awesome',
      icon: faDocker,
    },
  },
  git: {
    name: 'GIT',
    icon: {
      type: 'font-awesome',
      icon: faGitAlt,
    },
  },
  shopify: {
    name: 'Shopify',
    icon: {
      type: 'font-awesome',
      icon: faShopify,
    },
  },
  stripe: {
    name: 'Stripe',
    icon: {
      type: 'font-awesome',
      icon: faStripeS,
    },
  },
  aws: {
    name: 'AWS',
    icon: {
      type: 'font-awesome',
      icon: faAws,
    },
  },
  mongodb: {
    name: 'MongoDB',
    icon: {
      type: 'svg',
      icon: mongodbIcon,
    },
  },
  redux: {
    name: 'Redux',
    icon: {
      type: 'svg',
      icon: reduxIcon,
    },
  },
  heroku: {
    name: 'Heroku',
    icon: {
      type: 'svg',
      icon: herokuIcon,
    },
  },
  materialUI: {
    name: 'Material UI',
    icon: {
      type: 'svg',
      icon: materialUIIcon,
    },
  },
  sass: {
    name: 'SASS',
    icon: {
      type: 'svg',
      icon: sassIcon,
    },
  },
  webpack: {
    name: 'Webpack',
    icon: {
      type: 'svg',
      icon: webpackIcon,
    },
  },
  twilio: {
    name: 'Twilio',
    icon: {
      type: 'svg',
      icon: twilioIcon,
    },
  },
  socketIO: {
    name: 'Web Sockets',
    icon: {
      type: 'svg',
      icon: socketIOIcon,
    },
  },
  graphql: {
    name: 'GraphQL',
    icon: {
      type: 'svg',
      icon: graphqlIcon,
    },
  },
  postgresql: {
    name: 'PostgreSQL',
    icon: {
      type: 'svg',
      icon: postgresqlIcon,
    },
  },
  arcgis: {
    name: 'ArcGIS',
    icon: {
      type: 'svg',
      icon: arcgisIcon,
    },
  },
  typescript: {
    name: 'TypeScript',
    icon: {
      type: 'svg',
      icon: typescriptIcon,
    },
  },
  rollup: {
    name: 'Rollup',
    icon: {
      type: 'svg',
      icon: rollupIcon,
    },
  },
  babel: {
    name: 'Babel',
    icon: {
      type: 'svg',
      icon: babelIcon,
    },
  },
  storybook: {
    name: 'Storybook',
    icon: {
      type: 'svg',
      icon: storybookIcon,
    },
  },
  jest: {
    name: 'Jest',
    icon: {
      type: 'svg',
      icon: jestIcon,
    },
  },
  eslint: {
    name: 'ESLint',
    icon: {
      type: 'svg',
      icon: eslintIcon,
    },
  },
};
