import technologies from './technologies';

const {
  react,
  nodejs,
  mongodb,
  redux,
  javascript,
  sass,
  html,
  css,
  docker,
  bootstrap,
  webpack,
  graphql,
} = technologies;

export default [
  { score: 75, ...javascript },
  { score: 75, ...react },
  { score: 65, ...nodejs },
  { score: 60, ...mongodb },
  { score: 70, ...redux },
  { score: 65, ...graphql },
  { score: 70, ...html },
  { score: 70, ...css },
  { score: 70, ...bootstrap },
  { score: 65, ...sass },
  { score: 40, ...webpack },
  { score: 40, ...docker },
];
