import { makeId } from '../utils';

const myName = 'Haseeb Anwar';
const ogDescription = 'Developer, writer, and creator.';

const metadata = {
  home: {
    title: `${myName}`,
    description: ogDescription,
    url: '/',
  },
  portfolio: {
    title: `Portfolio | ${myName}`,
    description: `I've developed and maintained cutting-edge applications and solutions. Witness my passion for creating exceptional web experiences.`,
    url: '/portfolio',
  },
  skills: {
    title: `Skills | ${myName}`,
    description: 'Discover my expertise in software development technologies.',
    url: '/skills',
  },
  blog: {
    title: `Blog | ${myName}`,
    description:
      'Dive into my insightful blog posts on software development, design, and more.',
    url: '/blog',
  },
  contact: {
    title: `Contact | ${myName}`,
    description: 'Feel free to get in touch.',
    url: '/contact',
  },
  projects: [
    {
      id: makeId('Streetlogix'),
      title: `Streetlogix - ${myName}`,
      description: '',
      get url() {
        return `/portfolio/${this.id}`;
      },
    },
    {
      id: makeId('Create React Package'),
      title: `Create React Package | ${myName}`,
      description: '',
      get url() {
        return `/portfolio/${this.id}`;
      },
    },
    {
      id: makeId('Central Pro Services'),
      title: `Central Pro Services | ${myName}`,
      description: '',
      get url() {
        return `/portfolio/${this.id}`;
      },
    },
    {
      id: makeId('Pawket Treats'),
      title: `Pawket Treats | ${myName}`,
      description: '',
      get url() {
        return `/portfolio/${this.id}`;
      },
    },
  ],
};

function makeMetaData(metadataForPage) {
  const { title, description, url } = metadataForPage;
  const completeURL = `https://haseebanwar.com${url}`;

  return {
    title,
    headTags: [
      {
        name: 'link',
        data: [
          { label: 'rel', value: 'canonical' },
          { label: 'href', value: completeURL },
        ],
      },
      {
        name: 'meta',
        data: [
          { label: 'name', value: 'description' },
          {
            label: 'content',
            value: description,
          },
        ],
      },
      {
        name: 'meta',
        data: [
          { label: 'property', value: 'og:title' },
          { label: 'content', value: title },
        ],
      },
      {
        name: 'meta',
        data: [
          { label: 'property', value: 'og:url' },
          { label: 'content', value: completeURL },
        ],
      },
      {
        name: 'meta',
        data: [
          { label: 'property', value: 'og:description' },
          {
            label: 'content',
            value: ogDescription,
          },
        ],
      },
    ],
  };
}

const madeMetadata = Object.entries(metadata).reduce(
  (acc, [page, metadataForPage]) => {
    if (page === 'projects') {
      metadata[page].forEach((projectPageMetadata) => {
        acc[projectPageMetadata.id] = makeMetaData(projectPageMetadata);
      });
    } else {
      acc[page] = makeMetaData(metadataForPage);
    }

    return acc;
  },
  {}
);

export default madeMetadata;
