import React, { Component } from 'react';
import $ from 'jquery';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

class Toast extends Component {
  static defaultProps = {
    delay: 2000,
  };

  componentDidMount() {
    const { delay, isStatic, clearToastData } = this.props;
    const toast = $('.toast');

    // set toast options and show at initial
    toast.toast({ delay, autohide: !isStatic });
    toast.toast('show');

    // clear toast data in parent when toast closes
    toast.on(`hide.bs.toast`, clearToastData);
  }

  render() {
    const { type, title, msg } = this.props;

    return (
      <div
        className="Toast toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className={classnames('mr-2', {
              'text-success': type === 'success',
              'text-danger': type === 'error',
            })}
          />
          <strong className="mr-auto">{title}</strong>
          <button
            type="button"
            className="ml-2 mb-md-1 close"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {msg && <div className="toast-body">{msg}</div>}
      </div>
    );
  }
}

Toast.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  msg: PropTypes.string,
  delay: PropTypes.number.isRequired,
  isStatic: PropTypes.bool,
};

export default Toast;
