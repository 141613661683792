import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class Underline extends Component {
  render() {
    const { width, className } = this.props;

    return (
      <span
        className={classnames('Underline', className)}
        style={{ width }}
      ></span>
    );
  }
}

Underline.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Underline;
