import React, { Component } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import 'react-circular-progressbar/dist/styles.css';

class SkillProgress extends Component {
  static defaultProps = {
    valueStart: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.valueStart,
    };
    this.renderIcon = this.renderIcon.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ value: this.props.score });
    }, 0);
  }

  renderIcon() {
    const { icon } = this.props;
    switch (icon.type) {
      case 'font-awesome':
        return <FontAwesomeIcon icon={icon.icon} className="icon" />;
      case 'svg':
        return (
          <ReactSVG
            src={icon.icon}
            wrapper="span"
            beforeInjection={(svg) => {
              svg.classList.add('icon');
            }}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { value } = this.state;
    const { name, score } = this.props;

    return (
      <div className="SkillProgress">
        <CircularProgressbarWithChildren value={value} strokeWidth={5}>
          <div className="icon-wrapper">{this.renderIcon()}</div>
        </CircularProgressbarWithChildren>
        <p className="skill-name mt-3 mb-0">{name}</p>
        <p className="mb-0">{score}%</p>
      </div>
    );
  }
}

SkillProgress.propTypes = {
  valueStart: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default SkillProgress;
