import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

class Badge extends Component {
  constructor(props) {
    super(props);
    this.renderIcon = this.renderIcon.bind(this);
  }

  renderIcon() {
    const { icon } = this.props;
    switch (icon.type) {
      case 'font-awesome':
        return <FontAwesomeIcon icon={icon.icon} className="mr-2" />;
      case 'svg':
        return <ReactSVG src={icon.icon} wrapper="span" className="mr-2" />;
      default:
        return null;
    }
  }

  render() {
    const { name, icon } = this.props;

    return (
      <span className="Badge badge badge-light mr-2 my-1">
        {icon && this.renderIcon()}
        {name}
      </span>
    );
  }
}

Badge.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

export default Badge;
