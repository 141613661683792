import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class MainContent extends Component {
  render() {
    const { className, children, ...rest } = this.props;

    return (
      <main
        className={classnames('MainContent offset-md-3', className)}
        {...rest}
      >
        <div className="container px-md-5 py-5">{children}</div>
      </main>
    );
  }
}

MainContent.propTypes = {
  className: PropTypes.string,
};

export default MainContent;
