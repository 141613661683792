import React, { Component } from 'react';
import projects from './data/projects';
import blogPosts from './data/blog-posts';
import skills from './data/skills';

export const Context = React.createContext();

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects,
      blogPosts,
      skills,
      getProject: (id) =>
        this.state.projects.find((project) => project.id === id),
    };
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Consumer = Context.Consumer;
