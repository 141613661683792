import React, { Component } from 'react';
import { Provider } from './context';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import 'bootstrap';
import Sidebar from './misc/Sidebar';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
// import Skills from './pages/Skills';
import Blog from './pages/Blog';
// import Contact from './pages/Contact';
// import ProjectDetails from './pages/ProjectDetails';
import NotFound from './pages/NotFound';
import { Overlay } from './layouts';

import './App.scss';

class App extends Component {
  render() {
    return (
      <Provider>
        <Router>
          <ScrollToTop>
            <Overlay opacity={0.6} className="body-overlay" />
            <Sidebar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/portfolio" component={Portfolio} />
              {/* <Route
                  exact
                  path="/portfolio/:projectId"
                  component={ProjectDetails}
                /> */}
              {/* <Route exact path="/skills" component={Skills} /> */}
              <Route exact path="/blog" component={Blog} />
              {/* <Route exact path="/contact" component={Contact} /> */}
              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
