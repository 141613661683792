import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class Loading extends Component {
  render() {
    const { className } = this.props;

    return (
      <div className={classnames('Loading', className)}>
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
