import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

class Testimonial extends Component {
  render() {
    const { name, designation, text, avatar, url } = this.props.testimonial;

    return (
      <div className="Testimonial d-flex">
        <div className="mr-3 mr-lg-4">
          <FontAwesomeIcon
            className="quote-icon"
            icon={faQuoteLeft}
            size="2x"
          />
        </div>
        <div>
          <h6 className="sr-only">Testimonial</h6>
          <div className="text font-italic">{text}</div>
          <div className="person d-flex my-3">
            <div>
              <img className="rounded-circle shadow-sm" src={avatar} alt="" />
            </div>
            <div>
              <h6 className="name">
                {url ? (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {name}
                  </a>
                ) : (
                  name
                )}
              </h6>
              <p className="designation text-muted">{designation}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Testimonial.propTypes = {
  testimonial: PropTypes.shape({
    name: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
};

export default Testimonial;
