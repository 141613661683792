import React, { Component, Fragment } from 'react';
import { Context } from '../context';
import { MainContent, PostCard } from '../layouts';
import { injectPageMetaData } from '../utils';
import metadata from '../data/metadata';

class Blog extends Component {
  componentDidMount() {
    injectPageMetaData(metadata.blog);
  }

  render() {
    const { blogPosts } = this.context;

    return (
      <MainContent>
        <div className="Blog">
          <h1>Blog</h1>
          <p>
            Dive into my insightful blog posts on software development, design,
            and more.
          </p>
          <div className="py-4">
            {blogPosts.map((post, i) => (
              <Fragment key={post.title}>
                <PostCard post={post} />
                {i < blogPosts.length - 1 && <hr />}
              </Fragment>
            ))}
          </div>
        </div>
      </MainContent>
    );
  }
}

Blog.contextType = Context;

export default Blog;
