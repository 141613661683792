import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class Overlay extends Component {
  render() {
    const { opacity, className } = this.props;

    return (
      <div
        className={classnames('overlay', className)}
        style={{ background: `rgba(0, 0, 0, ${opacity})` }}
      ></div>
    );
  }
}

Overlay.prototypes = {
  opacity: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default Overlay;
