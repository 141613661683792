import $ from 'jquery';

export const makeId = (name) => {
  return name.toLowerCase().replace(/\s/g, '-');
};

export const injectPageMetaData = (page) => {
  // set page title
  document.title = page.title;
  // append new meta tags and remove previous page's tags
  page['headTags'].forEach((tag) => {
    // remove previous tag with same name / property
    $(`${tag.name}[${tag.data[0].label}="${tag.data[0].value}"]`).remove();
    // start making new tag
    let formattedTag = `<${tag.name} `;
    tag['data'].forEach((data) => {
      formattedTag += `${data.label}="${data.value}" `;
    });
    formattedTag += `/>`;
    // append newly made tag to head of the page
    $('head').append(formattedTag);
  });
};
