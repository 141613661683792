import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faGithub,
  faMedium,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';
import avatar from '../images/avatar.webp';
import usernames from '../data/usernames';

class Sidebar extends Component {
  render() {
    const { email, linkedin, twitter, github, medium } = usernames;

    return (
      <nav className="Sidebar col-md-3 py-4">
        <Link to="/" className="img-name-wrapper">
          <div className="img-container shadow-lg">
            <img src={avatar} alt="Haseeb" />
          </div>
          <div className="intro-content text-center mt-3">
            <h1 className="name">Haseeb Anwar</h1>
            <h2 className="subtitle">Full-Stack Engineer</h2>
          </div>
        </Link>
        <div className="main mt-md-4 px-3">
          <Navbar />
          <div className="reach-me mt-md-4 mt-2">
            <p className="reach-me-text">
              <small>Reach me at</small>
            </p>
            <div className="social-icons">
              <a
                href={`mailto:${email}`}
                title={`Mail: ${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faAt} />
              </a>
              <a
                href={`https://twitter.com/${twitter}`}
                title={`Twitter: ${twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href={`https://www.linkedin.com/in/${linkedin}/`}
                title={`LinkedIn: ${linkedin}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href={`https://github.com/${github}`}
                title={`GitHub: ${github}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a
                href={`https://${medium}.medium.com`}
                title={`Medium: ${medium}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faMedium} />
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
