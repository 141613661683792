import React, { Component } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

class ButtonLink extends Component {
  render() {
    const { icon, className, ...rest } = this.props;

    return (
      <button
        className={classnames('ButtonLink btn btn-link', className)}
        {...rest}
      >
        {icon && <FontAwesomeIcon icon={icon} className="icon mr-2" />}
        {this.props.children}
      </button>
    );
  }
}

ButtonLink.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string,
};

export default ButtonLink;
